import arrayFrom from 'array-from';

function hasClass(element, className) {
  if (!element) {
    return false;
  }
  return (element.getAttribute('class') || '').split(' ').some(cn => cn === className);
}

function addClass(element, className) {
  if (!element) {
    return;
  }

  if (
    NodeList.prototype.isPrototypeOf(element) ||
    Array.prototype.isPrototypeOf(element)
  ) {
    arrayFrom(element).forEach(subelement => addClass(subelement, className));
    return;
  }

  const classes = (element.getAttribute('class') || '').split(' ').filter(Boolean).filter(cn => cn !== className);
  classes.push(className);
  element.setAttribute('class', classes.join(' '));
}

function removeClass(element, className) {
  if (!element) {
    return;
  }

  if (
    NodeList.prototype.isPrototypeOf(element) ||
    Array.prototype.isPrototypeOf(element)
  ) {
    arrayFrom(element).forEach(subelement => removeClass(subelement, className));
    return;
  }

  const classes = (element.getAttribute('class') || '').split(' ').filter(Boolean).filter(cn => cn !== className);
  element.setAttribute('class', classes.join(' '));
}

function toggleClass(element, className) {
  if (!element) {
    return;
  }
  if (hasClass(element, className)) {
    removeClass(element, className);
  } else {
    addClass(element, className);
  }
}

function wrapElement(element, wrapNodeName, wrapClass = '') {
  const wrapperNode = document.createElement(wrapNodeName);
  if (wrapClass) {
    addClass(wrapperNode, wrapClass);
  }
  element.parentNode.insertBefore(wrapperNode, element);
  wrapperNode.appendChild(element);
  return wrapperNode;
}

export {
  addClass,
  removeClass,
  toggleClass,
  wrapElement,
}
