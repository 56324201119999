// import LazyLoad from "vanilla-lazyload";
import easydropdown from 'easydropdown';

import { toggleClass } from '../../../../../assets/javascript/tools/helpers';

class Distribution {
  constructor(options = {}) {
    this.element = options.element;
    this.titleImage = this.element.querySelector('.mod--distribution__media__title-image');
    this.titleImageSrc = this.titleImage.querySelector('img').dataset.src;
    this.titleImageSrcSet = this.titleImage.querySelector('img').dataset.srcset;
    this.select = this.element.querySelector('select');
    this.entryContent = this.element.querySelector('.mod--distribution__content__options__entry');
    this.entryJson = this.element.querySelector('[data-id="mod--distribution__option__entries"]').innerHTML;
  }

  init() {
    // console.log('Distribution', this.titleImage, this.select, JSON.parse(this.entryJson));
    const edd = easydropdown(this.select, {
      behavior: {
        useNativeUiOnMobile: false,
      },
    });

    this.initEvents();
  }

  initEvents() {
    this.select.addEventListener('change', () => {
      const selected = this.select.options[this.select.selectedIndex];
      // console.log(this.select, 'has changed', selected);
      this.changeData(selected.dataset.count);
    });
  }

  changeData(value) {
    this.entryContent.innerHTML = '';
    const distributor = JSON.parse(this.entryJson)[value];

    if (value !== 'x') {
      let template = '';
      if (distributor.title) {
        template += `<h3>${distributor.title}</h3>`;
      }

      if (distributor.street) {
        template += `<p>${distributor.street} ${distributor.streetNumber}<br/>`;
      }

      if (distributor.city) {
        template += `${distributor.zip} ${distributor.city}</p>`;
      }

      if (distributor.info) {
        template += `${distributor.info}`;
      }

      this.entryContent.innerHTML = template;

      this.titleImage.querySelector('img').src = distributor.titleImageSrc;
      this.titleImage.querySelector('img').srcset = distributor.titleImageSrcSet;

      // console.log(this.titleImage);
      // const lazyload = new LazyLoad({
      //   container: this.titleImage,
      //   elements_selector: 'img',
      // });
      // console.log(lazyload);
      // lazyload.update();
    } else if (value === 'x') {
      this.titleImage.querySelector('img').src = this.titleImageSrc;
      this.titleImage.querySelector('img').srcset = this.titleImageSrcSet;
    }

  }
}

export default Distribution;
