import DefaultSlider from '../../../organisms/org--default-slider';

class TeaserImageGroup extends DefaultSlider {
  constructor(options = {}) {
    super(options);
    this.autoplay = false;
    this.slides = this.element.querySelectorAll('.wrapper');
    this.arrows = true;
    this.breakpoints = {
      0: {
        perView: 1,
      },
      700: {
        perView: 1,
      },
      1200: {
        perView: 1,
      },
      2000: {
        perView: 1,
      },
    };
    // this.element = options.element;
    // this.slides = this.element.children;
    // console.log(this.element);
    this.ARROWS_CONTENT_RIGHT = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 40"><path d="M0 5.705v.828L13.467 20 0 33.467v.828L5.705 40h.828L20 26.533l6-6v-1.066l-6-6L6.533 0h-.828z"/></svg>';
    this.ARROWS_CONTENT_LEFT = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 40"><path d="M20.295 0h-.828L6 13.467l-6 6v1.066l6 6L19.467 40h.828L26 34.295v-.828L12.533 20 26 6.533v-.828z"/></svg>';
  }

  init() {
    super.init();
  }
}

export default TeaserImageGroup;
