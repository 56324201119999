import { addClass } from '../../../assets/javascript/tools/helpers';

class MainEntry {
  constructor(options = {}) {
    this.element = options.element;
  }

  init() {
    // console.log('I created a Main Entry');
    this.prepareCrossFunctionality();
  }

  prepareCrossFunctionality() {
    this.stage = document.querySelector('.mod--stage');
    if (this.stage !== null) {
      this.stageContent = this.stage.querySelector('.mod--stage__content');
      addClass(this.stageContent, 'inserted');

      this.element.insertBefore(this.stageContent, this.element.children[0]);
    }
  }
}

export default MainEntry;
