import arrayFrom from 'array-from';
import SmoothScroll from 'smooth-scroll';
import inView from 'in-view';

import { addClass, hasClass, toggleClass, removeClass } from '../../../../assets/javascript/tools/helpers';

const CLASSNAME_TOGGLE = 'main-navigation__toggle';
const CLASSNAME_OVERLAY = 'main-navigation__overlay';

class MainNavigation {
  constructor(options = {}) {
    this.element = options.element;
    this.toggle = this.element.querySelector(`.${CLASSNAME_TOGGLE}`);
    this.overlay = this.element.querySelector(`.${CLASSNAME_OVERLAY}`);
    this.logo = document.querySelector('.main-navigation__logo a');
    this.defaultContent = this.logo.innerHTML;
    this.allSections = document.querySelectorAll('.art--container__wrapper');
    this.allMenuEntries = document.querySelectorAll('.org--main-menu__entry');
  }

  init() {
    if (this.toggle !== null) {
      this.toggle.addEventListener('click', () => {
        toggleClass(this.element, 'overlay-active');
        toggleClass(this.overlay, 'active');
        toggleClass(this.toggle, 'active');
      });
    }

    if (this.toggle !== null) {
      this.overlay.addEventListener('click', () => {
        toggleClass(this.element, 'overlay-active');
        toggleClass(this.overlay, 'active');
        toggleClass(this.toggle, 'active');
      });
    };

    this.scrollToggle();
    this.applyInView();
    this.applySmoothScroll();
  }

  scrollToggle() {

    let scrollPos = window.pageYOffset;

    this.toggleSticky(scrollPos);

    window.addEventListener('scroll', (event) => this.toggleSticky(window.pageYOffset));
  }

  toggleSticky(posY) {
    // console.log(posY)
    const offset = 300;

    if (posY >= offset) {
      addClass(this.element, 'sticky');
    } else {
      removeClass(this.element, 'sticky');
    }
  }

  applyInView() {
    // console.log('Applying In View for Sections');

    this.sections = document.querySelectorAll('[data-section-title]');

    // console.log(arrayFrom(this.sections));
    inView.offset({
      top: window.innerHeight / 2,
      bottom: window.innerHeight / 3,
    });
    inView('.art--container__wrapper')
    .on('enter', (el) => {
      this.updateLogo(el);
      this.updateMenu(el);
    });
    // .on('exit', el => console.log('Exiting', el));

  }

  applySmoothScroll() {
    // console.log('Applying Smooth Scroll');
    const smoothScroll = new SmoothScroll('a[href*="#"]', {
      speed: 500,
      offset: 60,
    });
  }

  updateLogo(el) {
    // console.log(this.allSections[0], el === arrayFrom(this.allSections)[0]);
    // console.log(el);
    if (el === arrayFrom(this.allSections)[0] || el.dataset.sectionTitle === undefined) {
      // console.log('Erstes Element');
      this.logo.innerHTML = this.defaultContent;
    } else {
      // console.log('Was anderes');
      this.logo.innerHTML = el.dataset.sectionTitle;
    }
  }

  updateMenu(el) {
    // console.log('Menu: ', arrayFrom(this.allMenuEntries).filter(item => item.innerText === el.dataset.sectionTitle));
    arrayFrom(this.allMenuEntries).forEach((entry) => {
      removeClass(entry, 'active');
    })
    addClass(arrayFrom(this.allMenuEntries).filter(item => item.innerText === el.dataset.sectionTitle), 'active');
  }
}

export default MainNavigation;
